import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import {    BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';

import { ContextMenuModule } from 'primeng-lts/contextmenu';

// import { Tnarealization }

//import { Base64 } from 'js-base64';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [ ContextMenuModule, BrowserModule, HttpClientModule,IonicModule.forRoot(), AppRoutingModule,BrowserAnimationsModule],
  providers: [
    StatusBar,
    SplashScreen, BarcodeScanner,
    // MultiSelectModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
